<template>
<b-card class="w-100 mb-4" no-body>
    <div class="p-35">
        <div class="d-flex align-items-start">
            <div>
                <h4 class="card-title mb-1">Create Department</h4>
                <b-card-text class="text-muted">
                </b-card-text>
            </div>
        </div>
    </div>
    <hr class="m-0" />
    <b-card-body>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Department Name </label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-input id="name" type="text" v-model="$v.form.department_name.$model"
                            :state="validateState('department_name')"
            required placeholder="Department Name"></b-form-input>
            <b-form-invalid-feedback id="min-duration"
                            >This is a required field</b-form-invalid-feedback
                          >
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="border-bottom py-3 align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Department Head </label>
            </b-col>
            <!--<b-col sm="9">
                <b-form-group>
                <b-form-input id="name" type="text" v-model="$v.form.department_head_name.$model"
                            :state="validateState('department_head_name')"
            required placeholder="Department Name"></b-form-input>
            <b-form-invalid-feedback
                            >This is a required field</b-form-invalid-feedback
                          >
                </b-form-group>
            </b-col>-->
            <b-col sm="9">
                <b-form-group>
                <b-form-select :options="heads" v-model="$v.form.department_head_name.$model"
                            :state="validateState('department_head_name')"
            required placeholder="Department Name"></b-form-select>
            <b-form-invalid-feedback id="name"
                            >This is a required field</b-form-invalid-feedback
                          >
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Location </label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-select :options="locations" v-model="$v.form.location_id.$model"
                            :state="validateState('location_id')"
            required placeholder="Location"></b-form-select>
            <b-form-invalid-feedback id="Location"
                            >This is a required field</b-form-invalid-feedback
                          >
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0"> Status  </label>
            </b-col>
            <b-col sm="9">
                <b-form-group>
                <b-form-select :options="statuses" v-model="$v.form.status.$model"
                            :state="validateState('status')"
            required placeholder="Status"></b-form-select>
            <b-form-invalid-feedback id="Status"
                            >This is a required field</b-form-invalid-feedback
                          >
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <div class="p-35 border-top">
        <div class="btn-grp text-right">
            <b-button pill variant="primary" class="px-4" @click="saveDept()">Save</b-button>
            <b-button pill variant="danger" class="px-4">Cancel</b-button>
        </div>
    </div>
</b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
} from "vuelidate/lib/validators";
export default {
    name: "Add_Department",
    mixins: [validationMixin],
    data: () => ({
        form: {
            department_name: null,
            department_head_name: null,
            status: null,
            location_id: null,
            user_id: null
        },
        locations: [],
        heads: [],
        statuses: [],
    }),
    computed: {
        ...mapState({
        authId: (state) => state.auth.authId
        })
    },
    mounted() {
        this.initComp();
    },
    validations: {
    form: {
      department_name: {
        required,
        minLength: minLength(2),
      },
      department_head_name: {
        required,
        minLength: minLength(2),
      },
      status:{
          required,
      },
      location_id:{
        required,
      },
    },
  },
    methods: {
        ...mapActions(["getUsers","getLocations","getProductStatuses","saveDepartement"]),
        validateState(department_name) {
            const { $dirty, $error } = this.$v.form[department_name];
            return $dirty ? !$error : null;
        },
        initComp(){
            this.getUsers().then(res => {
                res.forEach(element => {
                    if(parseInt(element.user_type) === 6 && parseInt(element.phone) === parseInt(this.authId)){
                    this.heads.push({ value: element.id, text: element.full_name });
                    } 
                });
            })
            this.getLocations().then(res => {
                res.forEach(element => {
                   this.locations.push({ value: element.id, text: element.name }); 
                });
            });
            this.getProductStatuses().then(res => {
                res.forEach(element => {
                   this.statuses.push({ value: element.id, text: element.name }); 
                });
            });
        },
        saveDept(){
            this.form.user_id = this.authId;
            this.saveDepartement(this.form).then(res => {
                if('status' in res && res.status !== 200){
                    this.errorMsg = res.data;
                    this.error = true;
                }
                else{
                    this.successMsg = res;
                    this.success = true;
                    this.$bvToast.toast('Success', {
                        title: "Resource Added Successfully",
                        variant: 'success',
                        solid: true
                    })
                    this.$router.push({ name: 'Departments' });
                }
            })
        }
    }
};
</script>
